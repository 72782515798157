import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useRef } from "react"
import { Carousel } from "bootstrap"

const ServicesCarousel = () => {
  const carouselRef = useRef()
  useEffect(() => {
    // @TODO check if this if statement is needed
    if(typeof document !== "undefined") {
      new Carousel(carouselRef.current, {})
    }
  })
  return(
    <section>
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" ref={carouselRef}>
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div className="carousel-inner bg-secondary">
          <div className="carousel-item active">
            <Link to="/services/restaurant">
              <StaticImage src="../images/services/odesign-web-design-for-restaurants.png" className="d-block w-100" alt="Изработка на уебсайт за ресторанти"
                width={1200}
                aspectRatio={16/9}
                objectFit="contain"
                quality={100}
              />
            </Link>
          </div>
          <div className="carousel-item">
            {/* "/services#ecommerce" */}
            <Link to="/services/ecommerce">
              <StaticImage src="../images/services/odesign-web-design-for-ecommerce.png" className="d-block w-100" alt="Изработване на екомерс сайт"
                width={1200}
                aspectRatio={16/9}
                objectFit="contain"
                quality={100}
              />
            </Link>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </section>
  )
}

export default ServicesCarousel