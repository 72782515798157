import React from "react"
import Layout from "./../components/layout"
import { Link } from "gatsby"
import BlogPosts from "../components/blogPosts"
import SEO from "../components/seo"
import ServicesCarousel from "../components/servicesCarousel"

const IndexPage = (location) => {
  return (
    <Layout location={location}>
      <SEO />
      <ServicesCarousel />
      <section>
        <div className="container py-5" id="why-choose-us">
          <h2 className="pb-2 border-bottom">Защо да изберете нас</h2>
          <div className="row g-5 py-5">
            <div className="feature col-md-4">
              <div className="feature-icon bg-primary bg-gradient">
                <svg className="bi" width="1em" height="1em"><use xlinkHref="#collection"/></svg>
              </div>
              <h2>Гъвкавост</h2>
              <p>
              Започнете със само най-основното и позволете Вашият сайт да расте успоредно с Вашият бизнес.
              Възможности за плавен ъпгрейд.
              Не губите трафик.
              Печелите клиентите.
              </p>
            </div>
            <div className="feature col-md-4">
              <div className="feature-icon bg-primary bg-gradient">
                <svg className="bi" width="1em" height="1em"><use xlinkHref="#toggles2"/></svg>
              </div>
              <h2>Бърз сайт</h2>
              <p>Сайт, който се зарежда на секундата.</p>
              <p>Гарантирано.</p>
            </div>
            <div className="feature col-md-4">
              <div className="feature-icon bg-primary bg-gradient">
                <svg className="bi" width="1em" height="1em"><use xlinkHref="#people-circle"/></svg>
              </div>
              <h2>Оптимизация за търсачките (SEO)</h2>
              <p>Използвайки модерни техники за оптимизации, ние подсигуряваме по-лесното намиране на Вашият сайт чрез търсачки като Google.
Така изпъквате сред конкуренцията.</p>
            </div>

            <div className="feature col-md-4">
              <div className="feature-icon bg-primary bg-gradient">
                <svg className="bi" width="1em" height="1em"><use xlinkHref="#collection"/></svg>
              </div>
              <h2>Сигурност</h2>
              <p>
              За разлика от много сайтове, направени с платформи за уеб дизайн като WordPress, нашите сайтове са много по-добре подготвени за евентуални хакерски атаки, като например отказ от услуга (DDOS) или нерегламентиран достъп до админ панела.
              </p>
            </div>

            <div className="feature col-md-4">
              <div className="feature-icon bg-primary bg-gradient">
                <svg className="bi" width="1em" height="1em"><use xlinkHref="#collection"/></svg>
              </div>
              <h2>Адаптивен дизайн</h2>
              <p>
              Мобилните устройства се ползват повече от всякога. С нашия адаптивен дизайн Вие никога няма да откажете Вашите клиенти, без значение какво устройство използват те.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Link to="/contact/"><button className="btn btn-lg btn-cobalt align-center fs-2">Свържете се с нас</button></Link>
        </div>
      </section>
      <section>
        <div className="container py-5" id="blog">
          <h2 className="pb-2 border-bottom">От блога</h2>
          <div className="row g-5 py-5">
            <BlogPosts />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
